{
  "name": "pokemon-auto-chess",
  "version": "6.0.0",
  "description": "",
  "keywords": [],
  "license": "BSD-3-Clause",
  "engines": {
    "node": ">=20.16.0",
    "npm": ">=10.8.1"
  },
  "homepage": "https://github.com/keldaanCommunity/pokemonAutoChess#readme",
  "repository": {
    "type": "git",
    "url": "git+https://github.com/keldaanCommunity/pokemonAutoChess.git"
  },
  "bugs": {
    "url": "https://github.com/keldaanCommunity/pokemonAutoChess/issues"
  },
  "author": "keldaan-ag",
  "contributors": ["ogus", "sylvainpolletvillard"],
  "main": "public/dist/server/app/index.js",
  "scripts": {
    "reset": "rimraf -rf node_modules/",
    "dev-client": "node esbuild.js --dev",
    "dev-server": "ts-node-dev --watch \"src/**/*\" --respawn --transpile-only ./app/index.ts",
    "dev": "npm-run-all --parallel dev-*",
    "lint": "npx @biomejs/biome lint app/",
    "build-client": "node esbuild.js --build",
    "build-server": "tsc",
    "download-music": "rimraf -rf ./app/public/src/assets/musics && degit https://github.com/keldaanCommunity/pokemonAutoChessMusic ./app/public/src/assets/musics",
    "build": "npm run build-client && npm run build-server",
    "start": "node ./app/public/dist/server/app/index.js",
    "add-bot": "ts-node -T --preferTsExts ./db-commands/populate-bot-from-pastebin.ts",
    "seed-bots": "ts-node -T --preferTsExts ./db-commands/populate-bots-from-local.ts",
    "monitor-bot": "node ./app/public/dist/server/scheduled/monitor-bot.js",
    "translate": "npx @inlang/cli machine translate -f",
    "postinstall": "npm run download-music && cd edit/assetpack && npm install",
    "assetpack": "cd edit/assetpack && npm run assetpack",
    "precompute": "cd gen && ts-node precompute-all.ts",
    "colyseus-post-deploy": "npx colyseus-post-deploy",
    "deploy-live": "pm2 deploy production update"
  },
  "dependencies": {
    "@colyseus/command": "^0.3.1",
    "@colyseus/monitor": "^0.16.6",
    "@colyseus/redis-driver": "^0.16.1",
    "@colyseus/redis-presence": "^0.16.1",
    "@colyseus/schema": "^3.0.18",
    "@colyseus/testing": "^0.16.3",
    "@colyseus/tools": "^0.16.2",
    "@colyseus/ws-transport": "^0.16.3",
    "@joaomoreno/unique-names-generator": "^5.1.0",
    "@reduxjs/toolkit": "^2.2.7",
    "@types/bootstrap": "^5.2.10",
    "body-parser": "^1.20.3",
    "colyseus": "^0.16.3",
    "colyseus.js": "^0.16.9",
    "cors": "^2.8.5",
    "cron": "^3.1.6",
    "d3": "^7.8.5",
    "dayjs": "^1.11.13",
    "discord.js": "^14.15.1",
    "dotenv": "^16.3.2",
    "express": "^4.21.2",
    "express-basic-auth": "^1.2.0",
    "express-openapi": "^12.1.3",
    "fast-xml-parser": "^4.4.1",
    "firebase": "^10.12.4",
    "firebase-admin": "^12.3.0",
    "firebaseui": "^6.1.0",
    "fs-extra": "^11.2.0",
    "graceful-fs": "^4.2.10",
    "helmet": "^8.0.0",
    "i18next": "^23.12.2",
    "i18next-browser-languagedetector": "^8.0.0",
    "i18next-http-backend": "^2.4.2",
    "immer": "^10.0.3",
    "jimp": "^1.6.0",
    "loglevel": "^1.8.1",
    "markdown-to-config": "^0.4.0",
    "marked": "^15.0.0",
    "matter-js": "^0.20.0",
    "mongoose": "^8.9.5",
    "nanoid": "^3.3.8",
    "pastebin-ts": "^1.3.0",
    "phaser": "3.87.0",
    "phaser-animated-tiles-phaser3.5": "^2.0.5",
    "phaser3-rex-plugins": "^1.80.6",
    "pm2-prom-module-client": "^1.0.3",
    "prom-client": "^15.1.1",
    "react": "^18.2.0",
    "react-circular-progressbar": "^2.1.0",
    "react-dom": "^18.2.0",
    "react-i18next": "^15.0.0",
    "react-pro-sidebar": "^1.1.0-alpha.1",
    "react-redux": "^9.1.0",
    "react-router-dom": "^6.25.1",
    "react-tabs": "^6.0.2",
    "react-toastify": "^10.0.3",
    "react-tooltip": "5.21.5"
  },
  "devDependencies": {
    "@biomejs/biome": "^1.9.0",
    "@types/cors": "^2.8.17",
    "@types/d3": "^7.4.3",
    "@types/elo-rank": "^1.0.3",
    "@types/fs-extra": "^11.0.4",
    "@types/matter-js": "^0.19.7",
    "@types/node": "^22.5.4",
    "@types/node-fetch": "^2.6.11",
    "@types/react-dom": "^18.2.17",
    "csv-writer": "^1.6.0",
    "degit": "^2.8.4",
    "esbuild": "^0.25.0",
    "npm-run-all": "^4.1.5",
    "openapi-typescript": "^7.1.2",
    "pm2": "^5.4.2",
    "rimraf": "^6.0.1",
    "ts-node-dev": "^2.0.0",
    "typescript": "^5.7.3"
  }
}
