export enum EloRank {
  LEVEL_BALL = "LEVEL_BALL",
  NET_BALL = "NET_BALL",
  SAFARI_BALL = "SAFARI_BALL",
  LOVE_BALL = "LOVE_BALL",
  PREMIER_BALL = "PREMIER_BALL",
  QUICK_BALL = "QUICK_BALL",
  POKE_BALL = "POKE_BALL",
  SUPER_BALL = "SUPER_BALL",
  ULTRA_BALL = "ULTRA_BALL",
  MASTER_BALL = "MASTER_BALL",
  BEAST_BALL = "BEAST_BALL"
}
